import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { AnimalsApiService } from './animals-api.service';
import { AnimalDto } from './animals.typings';
import { EntityId } from '../../api-client/api-client.typings';

@Injectable({ providedIn: 'root' })
export class AnimalsProvider extends DataProvider<AnimalDto> {
    constructor(protected api: AnimalsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

    changeFarm(cardId: EntityId, farmId: EntityId, isErrorHandling = true): Observable<any> {
        return this.api.changeFarm(cardId, farmId).pipe(
            catchError(this.errorHandler.bind(this, isErrorHandling)),
        );
    }

  uploadPhoto(file: File, id: number, field: string, isErrorHandling = true): Observable<any> {
    return this.api
      .uploadPhoto(file, id, field)
      .pipe(catchError(this.errorHandler.bind(this, isErrorHandling)));
  }

  deletePhoto(id: number, isErrorHandling = true): Observable<any> {
    return this.api.deletePhoto(id).pipe(catchError(this.errorHandler.bind(this, isErrorHandling)));
  }
}
