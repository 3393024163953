import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { MessagesProvider } from 'src/app/core/services/messages/messages.provider';

// import { LeftSideMenuComponent } from './left-side-menu/left-side-menu.component';

export class UpdateChatMessage {
    public static channel = 'UpdateChatMessage';
    constructor() { }
}

@UntilDestroy()
@Component({
  selector: 'main-page',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  public showBackBtn = false;
  private isHide = true;

  public notificationsMap = {
    'messages': 0
  }

  // @ViewChild('left_menu') left_menu: LeftSideMenuComponent;

  public navLinks = [
    {
      label: 'app.main-animals.population',
      url: './animals/list',
    },
    {
      label: 'app.main-animals.inspections',
      url: './animals/events',
    },
    {
      label: 'app.main-animals.costs',
      url: './expenses',
    },
    {
      label: 'app.main-animals.incomes',
      url: './profits',
    },
    {
      label: 'app.store.title',
      url: './animals/store',
    },
    {
      label: 'Сообщения',
      url: './chats/list',
      notifyKey: 'messages'
    },
    {
      label: 'app.system-notifications.title',
      url: './notifications',
    },

    /*{
        label: 'app.main-animals.production',
        url: './production',
      },*/
  ];

  public leftsideMenuNavLinks = [
    {
      label: 'app.main-animals.population',
      url: './animals/list',
    },
    {
      label: 'app.main-animals.inspections',
      url: './animals/events',
    },
    {
      label: 'app.main-animals.costs',
      url: './expenses',
    },
    {
      label: 'app.main-animals.incomes',
      url: './profits',
    },
    {
      label: 'app.store.title',
      url: './store',
    },
    {
      label: 'app.system-notifications.title',
      url: './notifications',
    },
    {
      label: 'Сообщения',
      url: './chats/list',
      notifyKey: 'messages'
    },
    /*{
      label: 'app.main-animals.production',
      url: './production',
    },*/
  ];

  public activeLink: string = '';

  private intervalPolling = null;
  private ureadMessagesTrackerInit = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly messagesApi: MessagesProvider,
    private readonly cd: ChangeDetectorRef,
    protected readonly messageBus: MessageBusService,
    protected readonly authService: AuthenticationService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.firstChild),
        untilDestroyed(this),
      )
      .subscribe((route) => {
        this.activeLink = '.' + this.router.url;
        this.showBackBtn = !!this.getBackUri();
        this.cd.detectChanges();
      });

    this.showBackBtn = !!this.getBackUri();
  }

  ngOnInit(): void {
    if(this.intervalPolling) {
      clearInterval(this.intervalPolling);
      this.intervalPolling = null;
    } 
    this.getUreadMessages();
    this.intervalPolling = setInterval(()=> {
      this.getUreadMessages();
    }, 10000);
  }

  hideLeftMenu = false;
  hideRightMenu = false;

  public goBack(): void {
    this.router.navigate([this.getBackUri()]);
  }

  public getBackUri(): string {
    return this.findFirstChild(this.activatedRoute)?.snapshot?.data.backUri;
  }

  changeSizeLeftMenu(hide: any) {
    this.hideLeftMenu = hide;
  }

  changeSizeRightMenu(hide: any) {
    this.hideRightMenu = hide;
  }

  menuClick() {
    this.isHide = !this.isHide;
    // this.left_menu.hideOrShowMenu(this.isHide);
    this.cd.detectChanges();
  }

  private findFirstChild(route: ActivatedRoute): ActivatedRoute {
    return route?.firstChild ? this.findFirstChild(route?.firstChild) : route;
  }

  private getUreadMessages(): void {
    this.messagesApi
          .getList({
            'pagination[page]': `1`,
            'pagination[pageSize]': `1000`,
            ...this.getFilterDto('isRead', 0, true, '$null'),
            ...this.getFilterDto('recipientFarm', 1, this.authService.currentUser.farm_id, '$eq')
          })
          .pipe(
            untilDestroyed(this),
            finalize(() => {
              this.cd.detectChanges();
            }),
          )
          .subscribe((messagesList) => {
            if(this.ureadMessagesTrackerInit && this.notificationsMap['messages'] < messagesList.data.length) {
              this.messageBus.publish<UpdateChatMessage>(new UpdateChatMessage());
            }
            this.notificationsMap['messages'] = messagesList.data.length;
            this.ureadMessagesTrackerInit = true;
          });
  }

  private getFilterDto(name, index, value, operator): any {
      const key = `filters[$and][${index}][${name}][${operator}]`;
      return { [key]: value };
  }

  ngOnDestroy() {
    if(this.intervalPolling) {
      clearInterval(this.intervalPolling);
      this.intervalPolling = null;
    } 
  }
}
