import { environment } from 'src/environments/environment';

export function convertAnimalDto(list: any): any {
  return list.map((item) => {
    return {
      id: item.id,
      ...item.attributes,
      avatar: !!item.attributes.avatar?.data
        ? `${environment.uploadsHost}${item.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
        : '/assets/icons/empty-goat.png',
      lastReview: item.attributes.lastReview?.data
        ? {
            id: item.attributes.lastReview?.data.id,
            ...item.attributes.lastReview?.data.attributes,
          }
        : null,
      lastMilkYield: item.attributes.lastMilkYield?.data
        ? {
            id: item.attributes.lastMilkYield?.data.id,
            ...item.attributes.lastMilkYield?.data.attributes,
          }
        : null,
      photos: item.attributes.photos?.data?.map((photo) => {
        return {
          id: photo.id,
          ...photo.attributes,
        };
      }),
      mother: item.attributes.mother?.data
        ? {
            id: item.attributes.mother?.data.id,
            ...item.attributes.mother?.data.attributes,
            avatar: !!item.attributes.mother?.data.attributes.avatar?.data
              ? `${environment.uploadsHost}${item.attributes.mother?.data.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
              : '/assets/icons/empty-goat.png',
          }
        : null,
      father: item.attributes.father?.data
        ? {
            id: item.attributes.father?.data.id,
            ...item.attributes.father?.data.attributes,
            avatar: !!item.attributes.father?.data.attributes.avatar?.data
              ? `${environment.uploadsHost}${item.attributes.father?.data.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
              : '/assets/icons/empty-goat.png',
          }
        : null,
    };
  });
}
