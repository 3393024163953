import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfExportComponent } from './pdf-export.component';
import { ModalModule } from '../modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DateRangeModule } from '../inputs/daterange/daterange.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckboxModule } from '../inputs/checkbox/checkbox.module';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PdfExportComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,   
    TranslateModule, 
    ModalModule, 
    MatButtonModule,
    SharedPipesModule,
    DateRangeModule,
    MatExpansionModule,
    CheckboxModule,
    MatListModule,
    FormsModule,
  ],
  exports: [PdfExportComponent]
})
export class PdfExportModule { }
