import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { FarmDto } from './farms.typings';

@Injectable({providedIn: 'root'})
export class FarmsApiService extends ApiClient<FarmDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}farms`);
     }

     public getList(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<ApiList<FarmDto>> {
        return super.getList(params, headers)
        .pipe(map((list: any) => { 
          return { 
            data: list.data.map(item => {
                return {
                    id: item.id,
                    ...item.attributes,
                    logo: !!item?.attributes?.logo?.data 
                    ? `${environment.uploadsHost}${!!item?.attributes?.logo?.data?.attributes.formats.thumbnail.url}`
                    : '/assets/icons/empty-goat.png',
                }
            }),
            meta: list.meta
          }; 
        }))
      }

      public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<FarmDto> {
        return super.get(id, headers, params)
        .pipe(map(({ data }: any) => { 
            return {
                id: data.id,
                ...data.attributes,
                price_index: {
                  id: data.attributes.price_index?.data?.id,
                  ...data.attributes.price_index?.data?.attributes
                },
                sales_model: {
                  id: data.attributes.sales_model?.data?.id,
                  ...data.attributes.sales_model?.data?.attributes
                },
                location: {
                  id: data.attributes.location?.data?.id,
                  ...data.attributes.location?.data?.attributes
                }
            }
        }))
      }

      uploadPhoto(file: File, id: number, field: string): Observable<any> {
          const formData = new FormData();        
          formData.append('files', file, `${file.name}.png`);
          formData.append('ref', 'api::farm.farm');
          formData.append('refId', String(id));
          formData.append('field', field);
          return this.httpClient.post(`${environment.host}upload`, formData);
      }

      deletePhoto(id: number): Observable<any> {
          return this.httpClient.delete(`${environment.host}upload/files/${id}`);
      }
}
