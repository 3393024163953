import { Injectable } from '@angular/core';
import { AnimalsProvider } from '../../services/animals/animals.provider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AnimalDto,
  AnimalStateMap,
  GlobalAnimalStateMap,
  animalStateDict,
  animalStatusDict,
} from '../../services/animals/animals.typings';
import { PdfCreator } from '../creator/creator';
import { AppTranslateManagerService } from '../../translate/services/app-translate-manager.service';
import { forkJoin, lastValueFrom } from 'rxjs';
import { ExpenseDto, ExpenseTypes } from '../../services/expenses/expenses.typings';
import { ExpensesProvider } from '../../services/expenses/expenses.provider';
import { DateLocalizePipe } from 'src/app/shared/pipes/dates/date.localize.pipe';
import { ProfitsProvider } from '../../services/profits/profits.provider';
import { ProfitDto, ProfitTypes } from '../../services/profits/profits.typings';
import { ReviewsProvider } from '../../services/reviews/reviews.provider';
import { ReviewDto } from '../../services/reviews/reviews.typings';
import { MedicalRecordsProvider } from '../../services/medical-records/medical-records.provider';
import {
  MedicalRecordDto,
  MedicalRecordStatusesDict,
  MedicalRecordStatusesMap,
} from '../../services/medical-records/medical-records.typings';
import { MilkYieldsProvider } from '../../services/milk-yields/milk-yields.provider';
import { MilkYieldDto } from '../../services/milk-yields/milk-yields.typings';
import { InseminationsProvider } from '../../services/inseminations/inseminations.provider';
import { InseminationDto } from '../../services/inseminations/inseminations.typings';
import { BirthsProvider } from '../../services/births/births.provider';
import { BirthDto } from '../../services/births/births.typings';
import { DataProvider } from '../../data-provider/data-provider';

const COUNT_DATA_IN_QUERY = 3000;

interface DateRange {
  minDate: string;
  maxDate: string;
}

type journalFunction<T> = (
  pdf: any,
  data: T[][],
  needNewPage: Boolean,
  self: MainInfoPdfBuilder,
) => void;

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class MainInfoPdfBuilder {
  constructor(
    private readonly api: AnimalsProvider,
    private readonly pdfCreator: PdfCreator,
    private readonly translateManager: AppTranslateManagerService,
    private readonly apiExpenses: ExpensesProvider,
    private readonly datePipe: DateLocalizePipe,
    private readonly apiProfits: ProfitsProvider,
    private readonly apiReviews: ReviewsProvider,
    private readonly apiMedicalRecords: MedicalRecordsProvider,
    private readonly apiMilk: MilkYieldsProvider,
    private readonly apiInsemination: InseminationsProvider,
    private readonly apiBirth: BirthsProvider,
  ) {}

  private buildGoadListTable(pdf: any, data: AnimalDto[][]) {
    pdf.text(this.translateManager.translator.instant('app.main-animals.population'), 10, 10);
    const array = [];
    for (let curArray = 0; curArray < data.length; curArray++) {
      for (let curItem = 0; curItem < data[curArray].length; curItem++) {
        const item = data[curArray][curItem];
        array.push([
          this.getAnimalName(item),
          `${item.breed || '-'}, ${item.breed_purity || '-'}${item.breed_purity ? '%' : ''}`,
          this.translateManager.translator.instant(item.sex),
          this.datePipe.transform(item.birthdate),
          item.description,
          item.status
            ? this.translateManager.translator.instant(
                animalStateDict[AnimalStateMap[item.status]].value,
              )
            : '',
          this.translateManager.translator.instant(
            animalStatusDict[GlobalAnimalStateMap[item.global_status]].value,
          ),
        ]);
      }
    }
    this.pdfCreator.paintTable(
      pdf,
      [
        [
          this.translateManager.translator.instant('app.main-animals.table.name'),
          this.translateManager.translator.instant('app.animal-page.breed'),
          this.translateManager.translator.instant('app.main-animals.table.sex'),
          this.translateManager.translator.instant('app.global.fields.birthdate'),
          this.translateManager.translator.instant('app.global.fields.description'),
          this.translateManager.translator.instant('app.main-animals.table.state'),
          this.translateManager.translator.instant('app.main-animals.table.status'),
        ],
      ],
      array,
    );
  }

  private buildExpenseProfitListTable(
    pdf: any,
    data: ExpenseDto[][] | ProfitDto[][],
    text: string,
    needNewPage: boolean = true,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }

      pdf.text(text, 10, 10);
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([this.datePipe.transform(item.date), item.name, item.description, item.price]);
        }
      }
      this.pdfCreator.paintTable(
        pdf,
        [
          [
            this.translateManager.translator.instant('app.global.fields.date'),
            this.translateManager.translator.instant('app.global.fields.name'),
            this.translateManager.translator.instant('app.global.fields.description'),
            this.translateManager.translator.instant('app.global.fields.cost'),
          ],
        ],
        array,
      );
    }
  }

  private buildReviewJournal(
    pdf: any,
    data: ReviewDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      pdf.text(self.translateManager.translator.instant('app.reviews-page.title'), 10, 10);
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            self.datePipe.transform(item.date),
            item.description,
          ]);
        }
      }
      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.global.fields.description'),
          ],
        ],
        array,
      );
    }
  }

  private buildMedicalJournal(
    pdf: any,
    data: MedicalRecordDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }
      pdf.text(
        `${self.translateManager.translator.instant(
          'app.medical.title',
        )} ${self.translateManager.translator.instant('app.medical.diseases')}`,
        10,
        10,
      );
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            self.datePipe.transform(item.date),
            item.name,
            self.translateManager.translator.instant(
              MedicalRecordStatusesDict[MedicalRecordStatusesMap[item.status]].value,
            ),
          ]);
        }
      }

      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.global.fields.disease-name'),
            self.translateManager.translator.instant('app.main-animals.table.status'),
          ],
        ],
        array,
      );
    }
  }

  private buildMedicalProcedureJournal(
    pdf: any,
    data: MedicalRecordDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }

      pdf.text(
        `${self.translateManager.translator.instant(
          'app.medical.title',
        )} ${self.translateManager.translator.instant('app.medical.procedures.title')}`,
        10,
        10,
      );
      const array = [];
      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            self.datePipe.transform(item.date),
            item.name,
          ]);
        }
      }

      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.global.fields.name'),
          ],
        ],
        array,
      );
    }
  }

  private buildMilkJournal(
    pdf: any,
    data: MilkYieldDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }
      pdf.text(self.translateManager.translator.instant('app.global.fields.milk-yields'), 10, 10);
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            self.datePipe.transform(item.date),
            item.value,
            item.richness,
            item.comment,
          ]);
        }
      }

      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.milk-yields-page.fields.milk-yield'),
            self.translateManager.translator.instant('app.milk-yields-page.fields.richness'),
            self.translateManager.translator.instant('app.global.fields.comment'),
          ],
        ],
        array,
      );
    }
  }

  private buildInseminationJournal(
    pdf: any,
    data: InseminationDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }
      pdf.text(
        self.translateManager.translator.instant('app.events-page.menu.insemination'),
        10,
        10,
      );
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            self.datePipe.transform(item.date),
            self.datePipe.transform(item.dateRun),
            self.datePipe.transform(item.dateBirth),
            item.description,
          ]);
        }
      }

      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.insuminations-page.fields.date-run'),
            self.translateManager.translator.instant('app.insuminations-page.fields.date-birth'),
            self.translateManager.translator.instant('app.global.fields.description'),
          ],
        ],
        array,
      );
    }
  }

  private buildBirthJournal(
    pdf: any,
    data: BirthDto[][],
    needNewPage: Boolean = true,
    self: MainInfoPdfBuilder,
  ) {
    if (data && data.length > 0) {
      if (needNewPage) {
        pdf.addPage();
      }
      pdf.text(self.translateManager.translator.instant('app.events-page.menu.births'), 10, 10);
      const array = [];

      for (let curArray = 0; curArray < data.length; curArray++) {
        for (let curItem = 0; curItem < data[curArray].length; curItem++) {
          const item = data[curArray][curItem];
          array.push([
            self.getAnimalName(item.animal),
            item.regNumber,
            self.datePipe.transform(item.date),
            item.description,
          ]);
        }
      }

      self.pdfCreator.paintTable(
        pdf,
        [
          [
            self.translateManager.translator.instant('app.global.fields.animal'),
            self.translateManager.translator.instant('app.global.fields.birth-reg-num'),
            self.translateManager.translator.instant('app.global.fields.date'),
            self.translateManager.translator.instant('app.global.fields.description'),
          ],
        ],
        array,
      );
    }
  }

  private async createExpenceProfitExport(
    pdf: any,
    type: string,
    api: DataProvider<ExpenseDto | ProfitDto>,
    needNewPage: boolean = true,
    dateRange: DateRange = null,
    title = '',
  ): Promise<boolean> {
    const periodParams = {};
    if (dateRange) {
      if (dateRange.minDate) {
        periodParams['filters[$and][0][date][$gte]'] = dateRange.minDate;
      }
      if (dateRange.maxDate) {
        periodParams['filters[$and][0][date][$lte]'] = dateRange.maxDate;
      }
    }

    const data = [];
    const queryParams = {
      'filters[type][$eq]': type,
      'pagination[pageSize]': `${COUNT_DATA_IN_QUERY}`,
      ...periodParams,
    };

    let needReadData = true;
    let curPage = 1;
    while (needReadData) {
      queryParams['pagination[page]'] = curPage;
      const firstAnimalList$ = api.getList(queryParams);
      let curData = await lastValueFrom(firstAnimalList$);
      if (curData.data && curData.data.length > 0) {
        data.push(curData.data);
      }

      needReadData = curData && curData.meta?.pagination?.pageCount > curPage;
      curPage = curPage + 1;
    }

    if (data && data.length > 0) {
      this.buildExpenseProfitListTable(
        pdf,
        data,
        this.translateManager.translator.instant(title),
        needNewPage,
      );
      return true;
    } else {
      return false;
    }
  }

  private async createJournalExport<T>(
    pdf: any,
    api: DataProvider<T>,
    needNewPage: boolean = true,
    dateRange: DateRange = null,
    func: journalFunction<T>,
    additionalParams: object = {},
  ): Promise<boolean> {
    const periodParams = {};
    if (dateRange) {
      if (dateRange.minDate) {
        periodParams['filters[$and][0][date][$gte]'] = dateRange.minDate;
      }
      if (dateRange.maxDate) {
        periodParams['filters[$and][0][date][$lte]'] = dateRange.maxDate;
      }
    }

    const data = [];
    const queryParams = {
      'populate[animal][populate][0]': 'avatar',
      'pagination[pageSize]': `${COUNT_DATA_IN_QUERY}`,
      ...periodParams,
      ...additionalParams,
    };

    let needReadData = true;
    let curPage = 1;
    while (needReadData) {
      queryParams['pagination[page]'] = curPage;
      const firstAnimalList$ = api.getList(queryParams);
      let curData = await lastValueFrom(firstAnimalList$);
      if (curData.data && curData.data.length > 0) {
        data.push(curData.data);
      }

      needReadData = curData && curData.meta?.pagination?.pageCount > curPage;
      curPage = curPage + 1;
    }

    if (data && data.length > 0) {
      func(pdf, data, needNewPage, this);
      return true;
    } else {
      return false;
    }
  }

  private createPopulationPdf(data: AnimalDto[][]) {
    const pdf = this.pdfCreator.getPdf({ orientation: 'l' });
    this.buildGoadListTable(pdf, data);
    pdf.save(`popoulation_${new Date().toLocaleDateString()}.pdf`);
  }

  private async buildCostsExport(exportItems: number[], dateRange: DateRange) {
    const pdf = this.pdfCreator.getPdf({ orientation: 'l' });
    let needNewPage = false;
    for (let index = 0; index < exportItems.length; index++) {
      switch (exportItems[index]) {
        case 0:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ExpenseTypes.food,
            this.apiExpenses,
            needNewPage,
            dateRange,
            'app.foods-page.title',
          );
          break;
        case 1:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ExpenseTypes.medical,
            this.apiExpenses,
            needNewPage,
            dateRange,
            'app.expenses.med-expenses',
          );
          break;
        case 2:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ExpenseTypes.equipment,
            this.apiExpenses,
            needNewPage,
            dateRange,
            'app.equipments-page.title',
          );
          break;
        case 3:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ExpenseTypes.other,
            this.apiExpenses,
            needNewPage,
            dateRange,
            'app.expenses.other-expenses',
          );
          break;
        case 4:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ProfitTypes.milk,
            this.apiProfits,
            needNewPage,
            dateRange,
            'app.profits.milk-profit',
          );
          break;
        case 5:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ProfitTypes.cheese,
            this.apiProfits,
            needNewPage,
            dateRange,
            'app.profits.cheese-profit',
          );
          break;
        case 6:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ProfitTypes.curd,
            this.apiProfits,
            needNewPage,
            dateRange,
            'app.profits.curd-profit',
          );
          break;
        case 7:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ProfitTypes.yogurt,
            this.apiProfits,
            needNewPage,
            dateRange,
            'app.profits.yoghurt-profit',
          );
          break;
        case 8:
          needNewPage = await this.createExpenceProfitExport(
            pdf,
            ProfitTypes.other,
            this.apiProfits,
            needNewPage,
            dateRange,
            'app.profits.other-profuts',
          );
          break;

        default:
          break;
      }
    }
    pdf.save(`costs_${new Date().toLocaleDateString()}.pdf`);
  }

  private async buildJournalExport(exportItems: number[], dateRange: DateRange) {
    const pdf = this.pdfCreator.getPdf({ orientation: 'l' });
    let needNewPage = false;
    for (let index = 0; index < exportItems.length; index++) {
      switch (exportItems[index]) {
        case 0:
          needNewPage = await this.createJournalExport<ReviewDto>(
            pdf,
            this.apiReviews,
            needNewPage,
            dateRange,
            this.buildReviewJournal,
          );
          break;
        case 1:
          needNewPage = await this.createJournalExport<MedicalRecordDto>(
            pdf,
            this.apiMedicalRecords,
            needNewPage,
            dateRange,
            this.buildMedicalJournal,
            { 'filters[type][$null]': 'true' },
          );
          break;
        case 2:
          needNewPage = await this.createJournalExport<MedicalRecordDto>(
            pdf,
            this.apiMedicalRecords,
            needNewPage,
            dateRange,
            this.buildMedicalProcedureJournal,
            { 'filters[type][$eq]': 'medical_procedure' },
          );
          break;
        case 3:
          needNewPage = await this.createJournalExport<MilkYieldDto>(
            pdf,
            this.apiMilk,
            needNewPage,
            dateRange,
            this.buildMilkJournal,
          );
          break;
        case 4:
          needNewPage = await this.createJournalExport<InseminationDto>(
            pdf,
            this.apiInsemination,
            needNewPage,
            dateRange,
            this.buildInseminationJournal,
          );
          break;
        case 5:
          needNewPage = await this.createJournalExport<BirthDto>(
            pdf,
            this.apiBirth,
            needNewPage,
            dateRange,
            this.buildBirthJournal,
          );
          break;
        default:
          break;
      }
    }
    pdf.save(`journal_${new Date().toLocaleDateString()}.pdf`);
  }

  private async buildPopulationInfo() {
    const data = [];
    const queryParams = {
      populate: '*',
      'filters[$and][0][$or][0][deleted][$eq]': 'false',
      'filters[$and][0][$or][1][deleted][$null]': 'true',
      'pagination[pageSize]': `${COUNT_DATA_IN_QUERY}`,
    };

    let needReadData = true;
    let curPage = 1;
    while (needReadData) {
      queryParams['pagination[page]'] = curPage;
      const firstAnimalList$ = this.api.getList(queryParams);
      let curData = await lastValueFrom(firstAnimalList$);
      data.push(curData.data);

      needReadData = curData && curData.meta?.pagination?.pageCount > curPage;
      curPage = curPage + 1;
    }

    this.createPopulationPdf(data);
  }

  public buildFullPdf(
    buildPopulation = true,
    dateRange: DateRange,
    costData: number[] = [],
    journalData: number[] = [],
  ) {
    if (buildPopulation) {
      this.buildPopulationInfo();
    }
    if (costData && costData.length > 0) {
      this.buildCostsExport(costData, dateRange);
    }

    if (journalData && journalData.length > 0) {
      this.buildJournalExport(journalData, dateRange);
    }
  }

  private getAnimalName(animal): string {
    return `#${animal?.regNumber || '-'} ${animal?.name || '-'}`;
  }
}
