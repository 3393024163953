<div [fxLayout]="layout" fxLayoutAlign="start center" fxLayoutGap="16px">
    <ng-container *ngIf="!isMobileLayout">
        <notification-header></notification-header>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div 
                class="link"
                (click)="exportToPdf()">
                {{ 'app.global.export' | translate }}
            </div>
            <div class="separator"></div>            
        </div>
        <div showForLocale="ru" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div routerLink="/knowledge-base" class="link">{{ 'app.global.knowledgebase' | translate }}</div>
            <div class="separator"></div>            
        </div>
        <div showForLocale="ru" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <a href="https://vk.com/club229522511" target="_blank" class="link">
                <img width="30" style="margin-top: 6px;" src="/assets/images/vk-logo.png" />
            </a>
            <div class="separator"></div>            
        </div>
        <language-switcher></language-switcher>
        <div class="separator"></div>
    </ng-container>
    <user-info *ngIf="isAuth()" class="user-info"
            [showMenu]="!isMobileLayout"
            (logout)="logout()">
    </user-info>
    <div class="profile-menu" *ngIf="isMobileLayout && isAuth()" fxLayout="column">
        <div class="menu-item" mat-menu-item (click)="openMyProfile()">
            <span class="profile">{{ 'app.global.profile' | translate }}</span>
        </div>
        <div class="menu-item" mat-menu-item (click)="exit()">
            <span class="exit">{{ 'app.shared.left-side-menu.exit' | translate }}</span>
        </div>
    </div>
    <app-login-modal [show]="showLogin" (close)="showLogin = false"></app-login-modal>    
</div>

<app-pdf-export 
    [show]="isOpenPdfExpoft" 
    (close)="isOpenPdfExpoft = false">
</app-pdf-export>
