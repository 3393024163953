import { LanguageSwitcherModule } from './shared/components/languageswitcher/languageswitcher.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTranslateHttpLoader } from './core/translate/services/app-translate-http-loader';
import { MainModule } from './modules/main/main.module';
import { HomeComponent } from './modules/home/home.component';
import { AppTranslateModule } from './core/translate/app-translate.module';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { DateAdapter } from '@angular/material/core';
import { AppDateAdapter } from './shared/components/inputs/datepicker/date-adapter.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthModule } from './modules/auth/auth.module';
import { ErrorsHandlerModule } from './core/errors-handler/errors-handler.module';
import { PublicOfferModule } from './modules/public-offer/public-offer.module';
import { PrivacyPolicyModule } from './modules/privacy-policy/privacy-policy.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { environment } from 'src/environments/environment';
import { ScrollModule } from './shared/components/scroll/scroll.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorTranslate } from './core/helpers/paginator-translate';
import { SharedDirectivesModule } from './shared/directives/shared-directives.module';
import { DateAgoPipe } from './shared/pipes/dates/date-ago.pipe';
import { DateLocalizePipe } from './shared/pipes/dates/date.localize.pipe';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AppComponent, HomeComponent, NotificationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    /*MetrikaModule.forRoot(
      { id: 91345479, webvisor: false },
    ),*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: AppTranslateHttpLoader.createLoaderForRoot(
          {
            prefix: 'assets/i18n/',
            suffix: `.json?version=${environment.version}`,
          },
          {
            prefix: 'assets/i18n/shared/',
            suffix: `.json?version=${environment.version}`,
          },
        ),
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    AppTranslateModule,
    LanguageSwitcherModule,
    BrowserAnimationsModule,
    ErrorsHandlerModule,
    AuthModule,
    MainModule,
    FlexLayoutModule,
    PublicOfferModule,
    PrivacyPolicyModule,
    FooterModule,
    ScrollModule,
    SharedDirectivesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatSnackBarModule,
    MatIconModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: PaginatorTranslate },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: DateAgoPipe },
    { provide: DateLocalizePipe },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
