import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { MessageDto } from './messages.typings';

@Injectable({providedIn: 'root'})
export class MessagesApiService extends ApiClient<MessageDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}messages`);
     }

     public getList(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<ApiList<MessageDto>> {
        return super.getList(params, headers)
        .pipe(map((list: any) => { 
          return { 
            data: list.data.map(item => {
                return {
                    id: item.id,
                    ...item.attributes,
                }
            }),
            meta: list.meta
          }; 
        }))
      }

      public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<MessageDto> {
        return super.get(id, headers, params)
        .pipe(map(({ data }: any) => { 
            return {
                id: data.id,
                ...data.attributes,
            }
        }))
      }
}
