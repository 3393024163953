import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { MessagesApiService } from './messages-api.service';
import { MessageDto } from './messages.typings';

@Injectable({providedIn: 'root'})
export class MessagesProvider extends DataProvider<MessageDto> {
    constructor(protected api: MessagesApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
