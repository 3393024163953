
export interface AnimalDto {
    id: number;
    name: string;
    avatar: ImageDto;
    birthdate: string;
    sex: Sex;
    weight: number;
    growth: number;
    length: number;
    regNumber: number;
    description: string;
    milkYield: number;
    status: AnimalState;
    father: AnimalDto;
    mother: AnimalDto;
    global_status: AnimalStatus;
    photos: any;
    public_animal_card_id: number;
    breed: string;
    breed_purity: number;
    color: string;
    hornedness: string;
    withDoc: boolean;
}

export enum AnimalStatus {  
    in_herd = 'in_herd',
    from_another_farm = 'from_another_farm',
    sales = 'sales',
    killed = 'killed',
    perished = 'perished'
}

export const animalStatusDict = [
    {
        id: 'in_herd',
        value: 'app.animal.global-statuses.in_herd'
    },
    {
        id: 'from_another_farm',
        value: 'app.animal.global-statuses.from_another_farm'
    },
    {
        id: 'sales',
        value: 'app.animal.global-statuses.sales'
    },
    {
        id: 'killed',
        value: 'app.animal.global-statuses.killed'
    },
    {
        id: 'perished',
        value: 'app.animal.global-statuses.perished'
    },
];

export enum GlobalAnimalStateMap {
    in_herd,
    from_another_farm,
    sales,
    killed,
    perished
}

export enum Sex {
    male = 'male',
    female = 'female',
}

export enum AnimalState {  
    healthy = 'healthy',
    satisfactory = 'satisfactory',
    needObservation = 'needObservation', 
    sick = 'sick',
}

export enum AnimalStateMap {
    healthy,
    satisfactory,
    needObservation,
    sick,
}

export const animalStateDict = [
    {
        id: 'healthy',
        value: 'app.animal.statuses.healthy'
    },
    {
        id: 'satisfactory',
        value: 'app.animal.statuses.satisfactory'
    },
    {
        id: 'needObservation',
        value: 'app.animal.statuses.needObservation'
    },
    {
        id: 'sick',
        value: 'app.animal.statuses.sick'
    },
];

/*export const animalHornednessDict = [
    {
        id: 'horned',
        value: 'app.animal.hornedness-dist.horned'
    },
    {
        id: 'hornless',
        value: 'app.animal.hornedness-dist.hornless'
    },
    {
        id: 'dehorned',
        value: 'app.animal.hornedness-dist.dehorned'
    },
];*/

export const animalHornednessDict = [
    {
        id: 'horned',
        value: 'Рогатый(ая)'
    },
    {
        id: 'hornless',
        value: 'Комолый(ая)'
    },
    {
        id: 'dehorned',
        value: 'Обезрожен(а)'
    },
];

export const animalColorsDict = [
    { id: 'all', value: 'Любой' },
    {
        id: 'white',
        value: 'Сплошной белый'
    },
    {
        id: 'white-2',
        value: 'Желтовато-коричневый'
    },
    {
        id: 'white-2',
        value: 'Желтый'
    },
    {
        id: 'Asb',
        value: 'Соболиный'
    },
    {
        id: 'Abm',
        value: 'Черная маска'
    },
    {
        id: 'Acr',
        value: 'Карамельный'
    },
    {
        id: 'A+',
        value: 'A+ (дикий) - желтовато-коричневый'
    },
    {
        id: 'Ab',
        value: 'Барсучьи отметины'
    },
    {
        id: 'Ats',
        value: 'Загорелые бока (Tan sides)'
    },
    {
        id: 'Asc',
        value: 'Сан клементе'
    },
    {
        id: 'Arp',
        value: 'Репартида'
    },
    {
        id: 'Apk',
        value: 'Пеакок'
    },
    {
        id: 'Asg',
        value: 'Крапчато-серый'
    },
    {
        id: 'Ag',
        value: 'Серый'
    },
    {
        id: 'Asm',
        value: 'Швейцарские отметины'
    },
    {
        id: 'Asm2',
        value: 'Тоггенбургские отметины'
    },
    {
        id: 'Aeb',
        value: 'Брови'
    },
    {
        id: 'At',
        value: 'Подпалый'
    },
    {
        id: 'Afsh',
        value: 'Тусклый'
    },
    {
        id: 'Als',
        value: 'Боковые полосы'
    },
    {
        id: 'Am',
        value: 'Махагоновый'
    },
    {
        id: 'Arc',
        value: 'Красные щеки'
    },
    {
        id: 'Aa',
        value: 'Черный'
    },
    {
        id: 'Локус В',
        value: 'Коричневый'
    },
    {
        id: 'BD',
        value: 'Тёмно-коричневый'
    },
    {
        id: 'Bl',
        value: 'Светло-коричневый'
    },
    {
        id: 'В+',
        value: 'Эумеланин черного цвета'
    },
    {
        id: 'Bb',
        value: 'Красновато-коричневый'
    },
    {
        id: 'ptn',
        value: 'Пятнистый'
    },
    {
        id: 'ptn-11',
        value: 'Лунная пятнистость'
    },
    {
        id: 'ptn-2',
        value: 'Барбари'
    },
    {
        id: 'ptn-12',
        value: 'Серны'
    },
    {
        id: 'ptn-13',
        value: 'Два оттенка серны'
    },
    {
        id: 'ptn-3',
        value: 'Цветочный'
    },
    {
        id: 'ptn-4',
        value: 'Чалый'
    },
    {
        id: 'ptn-5',
        value: 'Алгарве'
    },
    {
        id: 'ptn-6',
        value: 'Нигерийский'
    },
    {
        id: 'ptn-7',
        value: 'Фростед'
    },
    {
        id: 'ptn-8',
        value: 'Крапчатый'
    },
    {
        id: 'ptn-9',
        value: 'Черношейная'
    },
    {
        id: 'ptn-10',
        value: 'Темноголовая'
    },   



];


export const animalBreedsDict = [
    { id: 'all', value: 'Любая' },
    { id: 'noBreed', value: 'Дворянская' },
    { id: 'Anglo-Nubian', value: 'Англо-нубийская' },
    { id: 'Saanen', value: 'Зааненская' },
    { id: 'Nubian', value: 'Нубийская' },
    { id: 'Abaza', value: 'Абхазская' },
    { id: 'Kamori', value: 'Камори' },
    { id: 'Russian White', value: 'Русская белая' },
    { id: 'Orenburg', value: 'Оренбургская' },
    { id: 'Abergelle', value: 'Абергельская' },
    { id: 'Adamello-Blond', value: 'Бионда дел Адамелло' },
    { id: 'Adany', value: 'Адани' },
    { id: 'Afar', value: 'Афарская' },
    { id: 'African Pygmy', value: 'Африканский (американский) пигмей' },
    { id: 'Akyab', value: 'Акьябская' },
    { id: 'Alashan-Cashmere', value: 'Алашаньская пуховая' },
    { id: 'Albanian', value: 'Албанская' },
    { id: 'Albas-White-Casmere', value: 'Албас' },
    { id: 'Algarvia', value: 'Алгарвиа' },
    { id: 'Allmogegetter', value: 'Шведская деревенская' },
    { id: 'Alpine', value: 'Альпийская' },
    { id: 'Alpine', value: 'Албанская альпина' },
    { id: 'Alpine-Polychrome', value: 'Альпина полихрома' },
    { id: 'Altai Local', value: 'Горноалтайская грубошёрстная' },
    { id: 'Altai-Mountain', value: 'Горноалтайская' },
    { id: 'Altain-ulaan', value: 'Алтайская красная' },
    { id: 'Amorgos', value: 'Аморгосская' },
    { id: 'Anatolian-Black', value: 'Анатолийская чёрная' },
    { id: 'Andalusian-Black-Mountain', value: 'Андалузская чёрная горная' },
    { id: 'Andalusian-White', value: 'Белая андалузская' },
    { id: 'Angora', value: 'Ангорская' },
    { id: 'Appenzell', value: 'Аппенцелльская' },
    { id: 'Aquila', value: 'Аквильская' },
    { id: 'Arab', value: 'Арабская' },
    { id: 'Arabia', value: 'Аравийская' },
    { id: 'Aradi', value: 'Ардийская' },
    { id: 'Aranitasi', value: 'Аранитасская белобокая' },
    { id: 'Aridaia', value: 'Аридейская' },
    { id: 'Armenian', value: 'Армянская грубошёрстная' },
    { id: 'Arsi-Bale', value: 'Арси-Балеская' },
    { id: 'Asmari', value: 'Асмарская' },
    { id: 'Aspromonte', value: 'Аспромонтская' },
    { id: 'Assam-Hill', value: 'Ассам-хилл' },
    { id: 'Aswad', value: 'Асвадская' },
    { id: 'Attapady-Black', value: 'Чёрная аттапади' },
    { id: 'Australian-All-Brown', value: 'Австралийская коричневая' },
    { id: 'Australian-Melaan', value: 'Австралийская чёрная' },
    { id: 'Australian-Miniature', value: 'Австралийская миниатюрная' },
    { id: 'Australian-Saanen', value: 'Австралийская зааненская' },
    { id: 'Azerbaijan', value: 'Азербайджанская грубошёрстная' },
    { id: 'Azpi-Gorri', value: 'Азпи-горри' },
    { id: 'Azul', value: 'Голубая' },
    { id: 'Bach-Thao', value: 'Бай-тао' },
    { id: 'Bagot', value: 'Багот' },
    { id: 'Baiyu-Black', value: 'Бэюская чёрная' },
    { id: 'Baladi', value: 'Баладийская' },
    { id: 'Balkan', value: 'Балканская' },
    { id: 'Baltistani', value: 'Балтистанская' },
    { id: 'Banat-White', value: 'Белая банатская' },
    { id: 'Bandundu-Dwarf', value: 'Бандундуская карликовая' },
    { id: 'Banjiao', value: 'Беньяойская' },
    { id: 'Bantu', value: 'Бантуская' },
    { id: 'Barbari', value: 'Барбари' },
    { id: 'Barbatsiko', value: 'Бородатая' },
    { id: 'Barcha', value: 'Барша́' },
    { id: 'Barka', value: 'Баркская' },
    { id: 'Barki', value: 'Сахарская' },
    { id: 'Barreña', value: 'Барренья' },
    { id: 'Batinah', value: 'Эль-батинская' },
    { id: 'Bayandelger-Red', value: 'Баяндэлгэрская красная' },
    { id: 'Beetal', value: 'Битал' },
    { id: 'Beiari', value: 'Бейяри' },
    { id: 'Belgian-Fawn', value: 'Бельгийская' },
    { id: 'Belgian-Red', value: 'Бельгийская красная' },
    { id: 'Belgian-Saanen', value: 'Белая бельгийская' },
    { id: 'Benadir', value: 'Бенадир' },
    { id: 'Benevento', value: 'Беневентская' },
    { id: 'Beni-Aarous', value: 'Бени-аррусская' },
    { id: 'Berari', value: 'Берари' },
    { id: 'Berber', value: 'Берберийская' },
    { id: 'Bermeya', value: 'Бермейская' },
    { id: 'Bhuj', value: 'Бхудж-бразилейра' },
    { id: 'Bimal', value: 'Бима́л' },
    { id: 'Biritinga', value: 'Биритингская' },
    { id: 'Black-Bengal', value: 'Бенгальская чёрная' },
    { id: 'Blue', value: 'Блобе' },
    { id: 'Boer', value: 'Бурская' },
    { id: 'Bombogoriin-ulaan', value: 'Бумбегерская красная' },
    { id: 'Booted-goat', value: 'Сардонская черноногая' },
    { id: 'Bormina', value: 'Бормина' },
    { id: 'Bornova', value: 'Борновская' },
    { id: 'Branca-sertaneja', value: 'Бранка-сертанежа' },
    { id: 'Bravia', value: 'Бра́виа' },
    { id: 'British-Alpine', value: 'Британская альпийская' },
    { id: 'British-Angora', value: 'Британо-ангорская' },
    { id: 'British-Cashmere', value: 'Британская пуховая' },
    { id: 'British-Saanen', value: 'Британо-зааненская' },
    { id: 'British-Toggenburg', value: 'Британо-тоггенбургская' },
    { id: 'Brown Alpine', value: 'Коричневая альпийская' },
    { id: 'Buchi', value: 'Бучи' },
    { id: 'Bugi-Toori', value: 'Бугитори' },
    { id: 'Bujri', value: 'Буджри' },
    { id: 'Bulgarian', value: 'Болгарская' },
    { id: 'Bulgarian-screw-horn-longhair', value: 'Болгарская винторогая' },
    { id: 'Bulgarian-White-Dairy', value: 'Болгарская белая молочная' },
    { id: 'Burmese', value: 'Бирманская' },
    { id: 'Burundi', value: 'Бурундийская' },
    { id: 'Buzguender', value: 'Бузгендер' },
    { id: 'Camosciata-delle-Alpi', value: 'Пассирийская горная' },
    { id: 'Campobasso', value: 'Кампобасская' },
    { id: 'Canary-Island', value: 'Канарская' },
    { id: 'Canindé', value: 'Канинде́' },
    { id: 'Capestrina', value: 'Капестрина' },
    { id: 'Capore', value: 'Капора' },
    { id: 'Carpathian', value: 'Карпатская' },
    { id: 'Castille-Mountain', value: 'Белая кастильская' },
    { id: 'Central-Highland', value: 'Центральноабиссинская горная' },
    { id: 'Chad', value: 'Арабская' },
    { id: 'Chaidamu', value: 'Цайдамская' },
    { id: 'Chamois-Coloured', value: 'Альпийская серна' },
    { id: 'Changthangi', value: 'Чангтханги' },
    { id: 'Chappar', value: 'Чаппар' },
    { id: 'Charnequeira', value: 'Чарнегуэйра' },
    { id: 'Cheeli', value: 'Чили' },
    { id: 'Chegu', value: 'Чигу' },
    { id: 'Chengde-Polled', value: 'Ченгде комолая' },
    { id: 'Chengdu Grey', value: 'Ченгду серая' },
    { id: 'Chuandong White', value: 'Чуаньдунская белая' },
    { id: 'Ciavenàsca', value: 'Кьявеннская' },
    { id: 'Cilento-Black', value: 'Чилентская чёрная' },
    { id: 'Cilento-Grey', value: 'Чилентская серая' },
    { id: 'Cilento-Fawn', value: 'Чилентская красная' },
    { id: 'Ciociara-Grey', value: 'Чочарская серая' },
    { id: 'Co', value: 'Ко' },
    { id: 'Colônia', value: 'Колонская' },
    { id: 'Colorada Pampeana', value: 'Пампинская красная' },
    { id: 'Corsican', value: 'Корсиканская' },
    { id: 'Cosenza', value: 'Козенцская' },
    { id: 'Creole', value: 'Криоле' },
    { id: 'Humahuaca', value: 'Умауакская' },
    { id: 'Criolla-Formoseña', value: 'Формосская' },
    { id: 'Criolla-Serrana', value: 'Криолла горная' },
    { id: 'Criolla-Neuquina', value: 'Неукенская' },
    { id: 'criolla-mendocina', value: 'Мендосская' },
    { id: 'Criollo', value: 'Криолло' },
    { id: 'Crioulo', value: 'Криуло' },
    { id: 'Croatian-spotted', value: 'Хорватская пёстрая' },
    { id: 'Croatian-white', value: 'Хорватская белая' },
    { id: 'Çukurova', value: 'Чукуровская' },
    { id: 'Cutch-cross', value: 'Катч-кросс' },
    { id: 'Cyprus', value: 'Кипрская' },
    { id: 'Czech-Brown-Short-haired', value: 'Чешская бурая' },
    { id: 'Czech-White-Short-haired', value: 'Белая чешская' },
    { id: 'Dadiangas', value: 'Дадиангасская' },
    { id: 'Dagestan', value: 'Дагестанская грубошёрстная' },
    { id: 'Dagestan-White', value: 'Дагестанская пуховая' },
    { id: 'Daiyun', value: 'Даюньская' },
    { id: 'Damani', value: 'Дамани' },
    { id: 'Damara', value: 'Дамара' },
    { id: 'Damascus', value: 'Дамасская' },
    { id: 'Danish Landrace', value: 'Датский ландрас' },
    { id: 'Dark-Head-Meat', value: 'Темноголовая мясная' },
    { id: 'Deccani', value: 'Деканская' },
    { id: 'Delgerehiin-ulaan', value: 'Дэлгэрэхсая красная' },
    { id: 'Dera-Din-Panah', value: 'Дера-дин-панах' },
    { id: 'Dervenohoria', value: 'Дервенохорийская' },
    { id: 'Desert', value: 'Сирийская пустынная' },
    { id: 'Dhaiwi', value: 'Бедуинская' },
    { id: 'Dhofari', value: 'Дофарская' },
    { id: 'Djallonké', value: 'Джалонка' },
    { id: 'gudbrandsdal', value: 'Гудбрандсдальская' },
    { id: 'pridonskaya', value: 'Придонская' },
    { id: 'Dorgon', value: 'Дургенская' },
    { id: 'Draa', value: 'Драаская' },
    { id: 'Dragobia', value: 'Драгобийская' },
    { id: 'Drežnica', value: 'Дрежницкая' },
    { id: 'Duan', value: 'Дуанская' },
    { id: 'Dukat', value: 'Дукатская' },
    { id: 'Dutch-Landrace', value: 'Голландский ландрас' },
    { id: 'Dutch-Pied', value: 'Голландская пёстрая' },
    { id: 'Dutch-Toggenburg', value: 'Голландская тоггенбургская' },
    { id: 'Dutch-White', value: 'Голландская белая' },
    { id: 'Elia', value: 'Элийская' },
    { id: 'English', value: 'Английская' },
    { id: 'English Guernsey', value: 'Английская гернсийская' },
    { id: 'Erchim Black', value: 'Эрчим-хар' },
    { id: 'Erzgebirge', value: 'Рудногорская' },
    { id: 'Estonian-Local', value: 'Эстонская' },
    { id: 'Etna-Silver', value: 'Этна серебряная' },
    { id: 'Fengqing-Horn-Down-Black', value: 'Фэнцинская безрогая' },
    { id: 'Fiji', value: 'Фиджийская' },
    { id: 'Finnish-Landrace', value: 'Финский ландрас' },
    { id: 'Fiurinà', value: 'Фиурина' },
    { id: 'Flemish', value: 'Фламандская' },
    { id: 'Florida', value: 'Флорида-севильяна' },
    { id: 'Fnideq', value: 'Фнидкская' },
    { id: 'Fossés', value: 'Фосская' },
    { id: 'Franconian', value: 'Франконийская' },
    { id: 'Fratari', value: 'Фратарская' },
    { id: 'French-Alpine', value: 'Французская альпийская' },
    { id: 'French-Saanen', value: 'Французская зааненская' },
    { id: 'Frisia', value: 'Фризская' },
    { id: 'Funiu-White', value: 'Фунюшаньская белая' },
    { id: 'Fuqing', value: 'Фуцинская' },
    { id: 'Fuyang', value: 'Фуянская' },
    { id: 'Gaddi', value: 'Гадди' },
    { id: 'Galsharin-ulaan', value: 'Галшарская красная' },
    { id: 'Ganjam', value: 'Ганьям' },
    { id: 'Ganxi', value: 'Ганьсийская' },
    { id: 'Garfagnina', value: 'Гарфаньянская' },
    { id: 'Garganica', value: 'Гарганика' },
    { id: 'Garre', value: 'Гарре' },
    { id: 'Gembrong', value: 'Джамбронг' },
    { id: 'German-Improved-Fawn', value: 'Бодливая немецкая' },
    { id: 'German-Improved-White', value: 'Немецкая белая улучшенная' },
    { id: 'German Meat', value: 'Немецкая мясная' },
    { id: 'Germasia', value: 'Немецко-азиатская' },
    { id: 'Ghazalia', value: 'Газали́я' },
    { id: 'Girgentana', value: 'Гиргентана' },
    { id: 'Gobi Wool', value: 'Говь-гурвансайхан' },
    { id: 'Gogo', value: 'Гого' },
    { id: 'Gohilwadi', value: 'Гохилвади' },
    { id: 'Göinge', value: 'Гёингская' },
    { id: 'Golden-Guernsey', value: 'Золотая гернсийская' },
    { id: 'Gorki', value: 'Горьковская' },
    { id: 'Gracani', value: 'Грачанская' },
    { id: 'Granada', value: 'Гранада' },
    { id: 'Graúna', value: 'Грау́на' },
    { id: 'Greek', value: 'Греческая' },
    { id: 'Greshices White', value: 'Грешицкая белая' },
    { id: 'Grey Mountain', value: 'Серая горная' },
    { id: 'Grisons Chamois-coloured', value: 'Гризонская серна' },
    { id: 'Grisons Striped', value: 'Grisons Chamois-coloured' },
    { id: 'Gruyère', value: 'Грюйерская' },
    { id: 'Guadarrama', value: 'Гвадарраменская' },
    { id: 'Guadalupe Island', value: 'Гваделупская' },
    { id: 'Guangfeng', value: 'Гуанфэнская' },
    { id: 'Guanzhong Dairy', value: 'Гуангзонгская' },
    { id: 'Guanzhong White', value: 'Гуангзонгская белая' },
    { id: 'Guariba', value: 'Гуари́ва' },
    { id: 'Guishan', value: 'Гуйшаньская' },
    { id: 'Guizhou White', value: 'Гуйчжоуская белая' },
    { id: 'Gulin Grey', value: 'Гулиньская серая' },
    { id: 'Gurguéia', value: 'Гургуэйская' },
    { id: 'Gwembe', value: 'Гвембе' },
    { id: 'Hailun', value: 'Хэйлунцзянская молочная' },
    { id: 'Haimen', value: 'Хаймэньская белая' },
    { id: 'Haimen', value: 'Хаймэньская белая' },
    { id: 'Hamra', value: 'Красная марокканская' },
    { id: 'Harerge Highland', value: 'Харарийская горная' },
    { id: 'Harz', value: 'Гарцская' },
    { id: 'Hasi', value: 'Хассийская' },
    { id: 'Hejazi', value: 'Хиджазская' },
    { id: 'Hexi Cashmere', value: 'Хэсинская пуховая' },
    { id: 'Honamli', value: 'Хонамлы' },
    { id: 'Hongtong', value: 'Хундунская' },
    { id: 'Hottentot', value: 'Готтентотская' },
    { id: 'Huaipi', value: 'Хуайпи' },
    { id: 'Huanghuai', value: 'Хуанхуайская' },
    { id: 'Hungarian', value: 'Венгерская' },
    { id: 'Hungarian Improved', value: 'Венгерская улучшенная' },
    { id: 'Ibicenca', value: 'Питиузская' },
    { id: 'Icelandic', value: 'Исландская' },
    { id: 'Ikaria', value: 'Икарийская' },
    { id: 'Indian Mohair', value: 'Индийская ангора' },
    { id: 'Indo-Chinese', value: 'Индокитайская' },
    { id: 'Ionica', value: 'Ионийская' },
    { id: 'Iranian Native Black', value: 'Иранская чёрная' },
    { id: 'Iraqi', value: 'Чёрная иракская' },
    { id: 'Irish', value: 'Ирландская' },
    { id: 'Israeli Saanen', value: 'Израильская зааненская' },
    { id: 'Istrian', value: 'Истрийская' },
    { id: 'Italian Alpine', value: 'Итальянская альпийская' },
    { id: 'Italian Saanen', value: 'Итальянская зааненская' },
    { id: 'Jabal Alakhdar', value: 'Эль-ахдарская' },
    { id: 'Jabaly', value: 'Джабали' },
    { id: 'Jabbali', value: 'Хаджарская' },
    { id: 'Jamnapari', value: 'Джамнапари' },
    { id: 'Jämtland', value: 'Емтландская' },
    { id: 'Japanese Saanen', value: 'Японская зааненская' },
    { id: 'Jarakheil', value: 'Джарахейл' },
    { id: 'Jattal', value: 'Джаттал' },
    { id: 'Jattan', value: 'Джатская' },
    { id: 'Jhakrana', value: 'Джакрана' },
    { id: 'Jianchang Black', value: 'Цзяньчанская чёрная' },
    { id: 'Jining Grey', value: 'Цзининская серая' },
    { id: 'Jordanian', value: 'Иорданская' },
    { id: 'Kabardino-Balkarian', value: 'Кабардино-балкарская' },
    { id: 'Kabyle', value: 'Кабильская' },
    { id: 'Kaghani', value: 'Кагхани' },
    { id: 'Kajli', value: 'Кайли' },
    { id: 'Kalahari Red', value: 'Калахарская красная' },
    { id: 'Kallmeti Spotted', value: 'Кальметская пятнистая' },
    { id: 'Kalofer long-haired', value: 'Калоферская' },
    { id: 'Kannaiadu', value: 'Каннаиаду' },
    { id: 'Karachai', value: 'Карачаевская грубошёрстная' },
    { id: 'Karatza', value: 'Караца' },
    { id: 'Karystou', value: 'Каристская' },
    { id: 'Kasaï', value: 'Касайская' },
    { id: 'Katjang', value: 'Катьянг' },
    { id: 'Kazakh', value: 'Казахская грубошёрстная' },
    { id: 'Kazakh Woolen', value: 'Казахская шёрстная' },
    { id: 'Kefa', value: 'Каффская' },
    { id: 'Kefalonia', value: 'Кефалинская' },
    { id: 'Kejobong', value: 'Кеджобонгская' },
    { id: 'Kenya Dual-Purpose', value: 'Кенийская мясо-молочная' },
    { id: 'Khurasani', value: 'Хорасани' },
    { id: 'Kigezi', value: 'Кигезийская' },
    { id: 'Kigoma', value: 'Кигомская' },
    { id: 'Kiko', value: 'Кико' },
    { id: 'Kilis', value: 'Килисская' },
    { id: 'Kinder', value: 'Киндер' },
    { id: 'Kirgiz', value: 'Киргизская грубошёрстная' },
    { id: 'Kodi Adu', value: 'Коди-аду' },
    { id: 'Kohai Ghizer', value: 'Шандурская' },
    { id: 'Kolonja Black', value: 'Колёнинская чёрная' },
    { id: 'Konkan Kanyal', value: 'Конканская' },
    { id: 'Kooti', value: 'Котская' },
    { id: 'Korean', value: 'Корейская' },
    { id: 'Kosta', value: 'Коста' },
    { id: 'Kottukachchiya', value: 'Коттукаччиянская' },
    { id: 'Kurdi', value: 'Курди' },
    { id: 'Kutchi', value: 'Катчи' },
    { id: 'Kutes Black', value: 'Кутская чёрная' },
    { id: 'KwaZulu-Natal', value: 'Квазулу-натальская' },
    { id: 'Kyrgyz Down', value: 'Киргизская пуховая' },
    { id: 'Kyrgyz Woolen', value: 'Киргизская (кыргызская) шёрстная' },
    { id: 'Labri', value: 'Лабри' },
    { id: 'Lakor', value: 'Лакорская' },
    { id: 'Lamancha', value: 'Американская ламанча' },
    { id: 'Landim', value: 'Мозамбикская' },
    { id: 'Laoshan Dairy', value: 'Лаошаньская' },
    { id: 'Lapland Dwarf', value: 'Лапландская карликовая' },
    { id: 'Lapp', value: 'Лапландская' },
    { id: 'Latvian Local', value: 'Латвийская' },
    { id: 'Leizhou', value: 'Лейзхоу' },
    { id: 'Lehri', value: 'Лехри' },
    { id: 'Lesvos', value: 'Лесбосская' },
    { id: 'Levadia', value: 'Левадия' },
    { id: 'Liaoning Cashmere', value: 'Ляонинская (пуховая)' },
    { id: 'Libyan', value: 'Ливийская' },
    { id: 'Linchang Longwool', value: 'Линьчанская длинношёрстная' },
    { id: 'Liqenas', value: 'Ликенасская' },
    { id: 'Lithuanian native', value: 'Литовская' },
    { id: 'Local longhair goat (Malashev type)', value: 'Малашевская длинношёрстная' },
    { id: 'Lohri', value: 'Лохри' },
    { id: 'Longlin', value: 'Лунлиньская' },
    { id: 'Longling', value: 'Лонглинская' },
    { id: 'Lubei White', value: 'Лубэйская белая' },
    { id: 'Luguru', value: 'Морогорская' },    
    { id: 'Lüliang Black', value: 'Люйлянская чёрная' },
    { id: 'Macedonian', value: 'Македонская' },
    { id: 'Machaeras', value: 'Махери́нская белая' },
    { id: 'Maguan Horn Down', value: 'Магуаньская безрогая' },
    { id: 'Majorcan', value: 'Майоркская' },
    { id: 'Majorera', value: 'Махарера' },
    { id: 'Makatia', value: 'Мака́тия' },
    { id: 'Malabari', value: 'Малабари' },
    { id: 'Málaga', value: 'Малагенская' },
    { id: 'Malawian', value: 'Малавийская' },
    { id: 'Maltese', value: 'Мальтийская' },
    { id: 'Malya', value: 'Мальская' },
    { id: 'Mamber', value: 'Мамбер' },
    { id: 'Mambrina Brasileira', value: 'Бразильская мамбрина' },
    { id: 'Maradi', value: 'Маради' },
    { id: 'Maritja', value: 'Марича' },
    { id: 'Marota', value: 'Марота' },
    { id: 'Martina', value: 'Мартина' },    
    { id: 'Marungu', value: 'Марунгуская' },
    { id: 'Marwari', value: 'Марвари' },
    { id: 'Masai', value: 'Масайская' },
    { id: 'Mashona', value: 'Машонская' },
    { id: 'Massif-Central', value: 'Овернская' },
    { id: 'Matabele', value: 'Матабельская' },
    { id: 'Mati', value: 'Матийская горная' },
    { id: 'Matou', value: 'Мату' },
    { id: 'Maure', value: 'Мавританская' },
    { id: 'Mauritian', value: 'Маврикийская' },
    { id: 'Mawr', value: 'Маурская' },
    { id: 'Mehsana', value: 'Мехсана' },
    { id: 'Meridional', value: 'Южнобразильская' },
    { id: 'Merturi', value: 'Мертурийская' },
    { id: 'Meseta', value: 'Месетская' },
    { id: 'Messinese', value: 'Мессинская' },
    { id: 'Mexican Criollo', value: 'Мексиканская криолло' },
    { id: 'Mingrelian', value: 'Мегрельская' },
    { id: 'Miriz', value: 'Мирицкая' },
    { id: 'Mochena Pied', value: 'Мокенская пятнистая' },
    { id: 'Moker', value: 'Мокрийская' },
    { id: 'Molai Adu', value: 'Молаи-аду' },
    { id: 'Moncaina', value: 'Монкайская' },
    { id: 'Mongolian', value: 'Монгольская' },
    { id: 'Montagnarde des Aurès', value: 'Оре́сская горная' },
    { id: 'Montejaqueña', value: 'Монтехакеская' },
    { id: 'Montichellana White', value: 'Бьянка монтичеллана' },
    { id: 'Moroccan Black', value: 'Чёрная марокканская' },
    { id: 'Mossi', value: 'Мосская' },
    { id: 'Moussoro', value: 'Муссоро' },
    { id: 'Moxotó', value: 'Моксото́' },
    { id: 'Mtwara', value: 'Мтвара' },
    { id: 'Mubende', value: 'Мубендская' },
    { id: 'Murcia-Granada', value: 'Мурсийско-гранадская' },
    { id: 'Murcian', value: 'Мурсийская' },
    { id: 'Murge', value: 'Мурдженская' },
    { id: 'Murme E Zeze', value: 'Албанская чёрная' },
    { id: 'Muzhakë', value: 'Мужакеская' },
    { id: 'Myotonic', value: 'Миотоническая' },
    { id: 'M’zabite', value: 'Мзабите' },
    { id: 'Nachi', value: 'Биканери' },
    { id: 'Najdi', value: 'Недждская' },
    { id: 'Nambi', value: 'Намби' },
    { id: 'Nanjiang Yellow', value: 'Наньцзянская жёлтая' },
    { id: 'Neapolitan', value: 'Неаполитанская' },
    { id: 'Negev', value: 'Негев' },
    { id: 'Nepalese Hill', value: 'Непальская горная' },
    { id: 'New Zealand Angora', value: 'Новозеландская ангорская' },
    { id: 'Newala', value: 'Невальская' },
    { id: 'Nguni', value: 'Нгуни' },
    { id: 'Nicastrese', value: 'Никастрская' },
    { id: 'Nigerian Dwarf', value: 'Нигерийская карликовая' },
    { id: 'Nigora', value: 'Нигора' },
    { id: 'Nonglin', value: 'Нунлиньская' },
    { id: 'Nordland', value: 'Нурланская' },
    { id: 'Norduz', value: 'Нордузская' },
    { id: 'Norwegian coastal', value: 'Прибрежная' },
    { id: 'Norwegian dairy', value: 'Норвежская молочная' },    
    { id: 'Oberhasli', value: 'Оберхасле-бриенцская' },
    { id: 'Okinawa', value: 'Окинавская' },
    { id: 'Orelha de Onça', value: 'Кошачьи уши' },    
    { id: 'Orobica', value: 'Оробийская' },
    { id: 'Osmanabadi', value: 'Османабади' },
    { id: 'Pafuri', value: 'Пафури' },
    { id: 'Pallai Adu', value: 'Паллаи-аду' },
    { id: 'Palmera', value: 'Пальмера' },
    { id: 'Panorusan Samosir', value: 'Самосирская' },
    { id: 'Papua New Guinea', value: 'Папуа-Ново-Гвинейская' },
    { id: 'Parbatsar', value: 'Парбатсар' },
    { id: 'Parda sertaneja', value: 'Парда сертанежа' },
    { id: 'Pare White', value: 'Парская белая' },
    { id: 'Pastoreño criollo', value: 'Пастореньо' },
    { id: 'Pateri', value: 'Патери' },
    { id: 'Peacock', value: 'Пятнистая' },
    { id: 'Pedula della Valtellina', value: 'Педула' },
    { id: 'Peranakan Etawah', value: 'Улучшенная этава' },
    { id: 'Peratiki', value: 'Ператикийская' },
    { id: 'Pezzata Rossa', value: 'Красно-пёстрая' },
    { id: 'Philippine', value: 'Филиппинская' },
    { id: 'Piamiri', value: 'Пиамири' },
    { id: 'Pinzgau', value: 'Пинцгауэр' },
    { id: 'Plateau', value: 'Плато' },
    { id: 'Poitou', value: 'Пуатусская' },
    { id: 'Polish Improved Fawn', value: 'Польская пёстрая улучшенная' },    
    { id: 'Polish Improved White', value: 'Польская белая улучшенная' },
    { id: 'Potenza', value: 'Потенцская' },
    { id: 'Pothohari', value: 'Потохари' },
    { id: 'Provençal', value: 'Провансальская' },
    { id: 'Psarogessa', value: 'Псарогеса' },
    { id: 'Pygmy', value: 'Пигмей' },
    { id: 'Pygora', value: 'Пигора' },
    { id: 'Pyrenean', value: 'Пиренейская' },
    { id: 'Qinshan', value: 'Циньшаньская' },
    { id: 'Raini', value: 'Райне' },
    { id: 'Ramdhan', value: 'Рамдхан' },
    { id: 'Rasquera White', value: 'Раскерская белая' },
    { id: 'Red Bosnian', value: 'Красная боснийская' },
    { id: 'Red Mediterranean', value: 'Красная средиземноморская' },
    { id: 'Repartida', value: 'Репартида' },
    { id: 'Retinta', value: 'Ретинта' },
    { id: 'Réunion Créole', value: 'Реюньонская' },
    { id: 'Rila Monastery', value: 'Рило-монастырская' },    
    { id: 'Roccaverano', value: 'Роккаверанская' },
    { id: 'Rodos', value: 'Родосская' },
    { id: 'Roman', value: 'Романская' },
    { id: 'Rove', value: 'Ров' },
    { id: 'Roya Vésubie', value: 'Везюби-руайанская' },
    { id: 'Russian Central Asian Local', value: 'Среднеазиатская грубошёрстная' },    
    { id: 'Rwanda-Burundi', value: 'Руанда-бурундийская' },    
    { id: 'Sable Saanen', value: 'Цветная зааненская' },
    { id: 'Saburai', value: 'Сабурай' },
    { id: 'Sahelian', value: 'Сахелийская' },
    { id: 'Sahrawi', value: 'Сахаравийская' },
    { id: 'Saidi', value: 'Саиди' },
    { id: 'Sakhar', value: 'Сакарская' },
    { id: 'Salem Black', value: 'Салемская чёрная' },
    { id: 'Samos', value: 'Самосская' },
    { id: 'San Clemente', value: 'Сан-клементская' },    
    { id: 'Sanbei', value: 'Саньбэйская' },
    { id: 'Sangamneri', value: 'Саннамнери' },
    { id: 'Santandereana', value: 'Сантандерская' },
    { id: 'Sardinian', value: 'Сардинская' },
    { id: 'Savanna', value: 'Саванна' },
    { id: 'Savanna Brown', value: 'Нигерийская' },
    { id: 'Sempione', value: 'Зимплонская' },
    { id: 'Senduro', value: 'Сендурская' },
    { id: 'Serbian White', value: 'Сербская белая' },
    { id: 'Serpentina', value: 'Серпентина' },
    { id: 'Serrana', value: 'Серрана' },
    { id: 'Sfakia', value: 'Сфакьянская' },
    { id: 'Shannan White', value: 'Шанская белая' },
    { id: 'Shekhawati', value: 'Шехаватская' },
    { id: 'Shiba', value: 'Сиба' },
    { id: 'Shurri', value: 'Шурри' },
    { id: 'Sicilian', value: 'Сицилийская' },
    { id: 'Sinai', value: 'Чёрная синайская' },    
    { id: 'Sinazongwe', value: 'Синазонгвенская' },
    { id: 'Sind Desi', value: 'Синддези' },
    { id: 'Sinhal', value: 'Синхал' },
    { id: 'Sirli', value: 'Сирли' },
    { id: 'Sirohi', value: 'Сирохи' },
    { id: 'Skilder', value: 'Скилдер' },
    { id: 'Skopelos', value: 'Скопелос' },
    { id: 'Slovenian Alpine', value: 'Словенская альпийская' },
];


export const animalAgeRangesDict = [
    {
        id: 'all',
        value: 'Любой'
    },
    {
        id: 'oneYears',
        value: 'До 1 года'
    },
    {
        id: 'twoYears',
        value: 'От 1 года до 2-х лет'
    },
    {
        id: 'threeYears',
        value: 'От 1 года до 3-х лет'
    },
    {
        id: 'fourYears',
        value: 'От 1 года до 4-х лет'
    },
    {
        id: 'fiveYears',
        value: 'От 1 года до 5-х лет'
    },
    {
        id: 'moreFiveYears',
        value: 'Более 5-х лет'
    },
];

export interface ImageDto {
    data: {
        id: number;
        attributes: {
            url: string;            
        }
    };
}